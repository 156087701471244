<template>
    <div class="privacyNotice">
        <div class="privacyNotice-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Privacy Notice</h5>
        </div>
        <div class="privacyNotice-main">
            <p>SOYEGO does not actively or engage in any customer data sharing with any companies, entities or individuals. We collect personal data from customers to enable us to process your order. This may include your name, address, telephone number, email address and sometimes IP address. This data is collected when you create a profile on our website or when you complete a purchase. This data will not be shared to any other companies, entities or individuals.</p>
            <p>If you want to exercise your privacy rights, or have any other concerns, questions, or complaints regarding privacy, please submit your question to <span>privacy@soyego.com</span>.</p>
            <p>Our privacy team will respond to privacy related questions only, for general customer support inquiries, please contact <span>info@soyego.com</span>.</p>
        </div>
    </div>
</template>

<style>
.about-content{
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
.privacyNotice {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .privacyNotice-title {
        width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;

        .back {
            position: absolute;
            display: flex;
            align-items: center;
            cursor: pointer;
            left: 20px;

            span {
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: Regular;
                color: #330000;
                padding-left: 6px;
            }
        }

        h5 {
            font-size: 30px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            color: #330000;
        }
    }

    .privacyNotice-main {
        width: calc(100% - 40px);
        padding: 38px 20px 351px;
        display: flex;
        flex-direction: column;

        p {
            font-size: 14px;
            font-family: Montserrat,SF-UI;
            font-weight: 400;
            color: #330000;
            padding-top: 32px;

            span{
                color: #437EF5;
            }
        }
    }
}
</style>

<script>
export default {
    name: "privacyNotice",
    components: {},
    data: () => {
        return {}
    },
    created() {},
    mounted() {},
    methods: {
        backClick() {
            this.$router.go(-1)
        }
    }
}
</script>
